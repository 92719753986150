import * as React from "react";
import { reloadFactory } from "./reloadFactory";
export const RegisterFeatureAppContext = React.createContext({
    reload: () => {
        // nothing to do
    },
});
export const RegisterFeatureAppContextProvider = (props) => {
    const { refetch, setLoading, timeout, children } = props;
    const value = {
        reload: (env, version, paths) => {
            reloadFactory(refetch, setLoading, timeout)((response) => check(response, paths, env, version));
        },
    };
    return (React.createElement(RegisterFeatureAppContext.Provider, { value: value }, children));
};
export function check(response, paths, env, version) {
    const pathToCurrentVersion = {};
    response.allFeatureApps.forEach((fa) => {
        var _a;
        return (_a = fa.configurations) === null || _a === void 0 ? void 0 : _a.forEach((c) => {
            var _a;
            return (_a = c.servers) === null || _a === void 0 ? void 0 : _a.filter((s) => s.server.name === env).forEach((s) => {
                if (s.config.path && s.config.version)
                    pathToCurrentVersion[s.config.path] = s.config.version;
            });
        });
    });
    const updatedPaths = paths.filter((path) => {
        return version === pathToCurrentVersion[path];
    });
    return paths.length === updatedPaths.length;
}
