import React, { useContext } from "react";
import { StyledTable, StyledTd, StyledTh } from "./Table";
import { ServiceContext } from "../context/ServiceContext";
import { gql } from "../../__generated__";
import { config } from "../queries/config";
import { useQuery } from "@apollo/client";
import { Container, ContainerGutter, ContainerPadding, FocusLayer, FocusLayerSize, LayerWrapper, ShimLayer, } from "@volkswagen-onehub/components-core";
export const AppConfigLayer = () => {
    const layerManager = useContext(ServiceContext).layerManager;
    const configQuery = gql(config);
    const { data } = useQuery(configQuery);
    const body = data ? (React.createElement("tbody", null,
        React.createElement("tr", null,
            React.createElement(StyledTd, { rowSpan: 3 }, "aem author api"),
            React.createElement(StyledTd, null, "prod"),
            React.createElement(StyledTd, null,
                " ",
                data.config.aemAuthorApi.prod)),
        React.createElement("tr", null,
            React.createElement(StyledTd, null, "int"),
            React.createElement(StyledTd, null,
                " ",
                data.config.aemAuthorApi.int)),
        React.createElement("tr", null,
            React.createElement(StyledTd, null, "stage"),
            React.createElement(StyledTd, null, data.config.aemAuthorApi.stage)),
        React.createElement("tr", null,
            React.createElement(StyledTd, { rowSpan: 3 }, "aem publisher"),
            React.createElement(StyledTd, null, "prod"),
            React.createElement(StyledTd, null,
                " ",
                data.config.aemPublishEnvs.prod)),
        React.createElement("tr", null,
            React.createElement(StyledTd, null, "int"),
            React.createElement(StyledTd, null,
                " ",
                data.config.aemPublishEnvs.int)),
        React.createElement("tr", null,
            React.createElement(StyledTd, null, "stage"),
            React.createElement(StyledTd, null,
                " ",
                data.config.aemPublishEnvs.stage)),
        React.createElement("tr", null,
            React.createElement(StyledTd, null, "app store"),
            React.createElement(StyledTd, { colSpan: 2 }, data.config.appStore)),
        React.createElement("tr", null,
            React.createElement(StyledTd, null, "oscar"),
            React.createElement(StyledTd, { colSpan: 2 }, data.config.oscar)))) : (React.createElement("tbody", null));
    return (React.createElement(React.Fragment, null,
        React.createElement(LayerWrapper, { contentCentered: true },
            React.createElement(ShimLayer, { key: "shim", onClick: () => layerManager.closeAlert() }),
            React.createElement(FocusLayer, { key: "layer", active: true, size: FocusLayerSize.A },
                React.createElement(Container, { gutter: ContainerGutter.static400, horizontalAlign: "center", padding: ContainerPadding.static450, verticalAlign: "center", wrap: "always", stretchContent: true },
                    React.createElement(StyledTable, null,
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement(StyledTh, null, "endpoint"),
                                React.createElement(StyledTh, { colSpan: 2 }, "url"))),
                        body))))));
};
