import * as React from "react";
import { StyledTh, StyledTable, StyledTd } from "./Table";
import { Container, CTA, Text, TokenTextAppearance, } from "@volkswagen-onehub/components-core";
import { sortVersion } from "../utils/sortVersion";
import { getVersionAnchor } from "./getVersionAnchor";
import { ServiceContext } from "../context/ServiceContext";
import { FeatureAppVersionLayer, } from "./FeatureAppVersionLayer";
import { formatDate } from "./formatDate";
export const FeatureAppVersionTable = (props) => {
    const layerManager = React.useContext(ServiceContext).layerManager;
    const { releases, deploymentSet } = props;
    return releases && releases.length ? (React.createElement(Container, { gutter: "dynamic0200", wrap: "always" },
        React.createElement(Text, { appearance: TokenTextAppearance.headline300 }, "Releases"),
        React.createElement(StyledTable, null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement(StyledTh, null, "version"),
                    React.createElement(StyledTh, null, "date"),
                    React.createElement(StyledTh, null, "dependencies"),
                    React.createElement(StyledTh, null, "jira id"),
                    React.createElement(StyledTh, null, "jira state"),
                    deploymentSet && React.createElement(StyledTh, null, "feature apps"))),
            React.createElement("tbody", null, releases
                .sort((r1, r2) => r1.version && r2.version
                ? sortVersion(r1.version, r2.version)
                : 0)
                .map((release, index) => {
                const { version, publishedAt, includedFeatureApps, releasePageUrl, generalReleasePageUrl, issue, } = release;
                const { fields, key: jiraKey } = issue || {};
                const { name: jiraStatus } = (fields === null || fields === void 0 ? void 0 : fields.status) || {};
                const releaseUrl = releasePageUrl || generalReleasePageUrl;
                const jiraUrl = jiraKey
                    ? `https://lso.volkswagen.de/bctbt/browse/${jiraKey}`
                    : undefined;
                return (React.createElement("tr", { key: index },
                    React.createElement(StyledTd, { id: getVersionAnchor(version || "none") },
                        React.createElement(CTA, { tag: "a", emphasis: "tertiary", href: releaseUrl || "", disabled: !releaseUrl, target: "_blank" }, version)),
                    publishedAt && (React.createElement(StyledTd, null, formatDate(publishedAt))),
                    React.createElement(StyledTd, null,
                        React.createElement(CTA, { tag: "a", emphasis: "tertiary", onClick: () => {
                                layerManager.openAlert(React.createElement(FeatureAppVersionLayer, { version: release, includedApps: includedFeatureApps.map((a) => a.name) }));
                            } }, "view")),
                    React.createElement(StyledTd, null,
                        React.createElement(CTA, { tag: "a", emphasis: "tertiary", href: jiraUrl, target: "_blank" }, jiraKey)),
                    React.createElement(StyledTd, null, jiraStatus),
                    deploymentSet && (React.createElement(StyledTd, null, includedFeatureApps.map((f, idx) => f.releasePageUrl ? (React.createElement(React.Fragment, null,
                        " ",
                        React.createElement(CTA, { target: "_blank", tag: "a", emphasis: "tertiary", href: f.releasePageUrl }, f.name),
                        idx < includedFeatureApps.length - 1 && ", ")) : (React.createElement(React.Fragment, null,
                        " ",
                        f.name,
                        idx < includedFeatureApps.length - 1 && ", ")))))));
            }))))) : null;
};
