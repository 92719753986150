import React, { useContext, useState } from "react";
import { detailFeatureApp } from "../queries/FeatureApp";
import { useQuery } from "@apollo/client";
import { gql } from "../../__generated__";
import { Loading } from "../component/Loading";
import { RegisterFeatureAppComponent, } from "../component/register/RegisterFeatureAppComponent";
import { ArticleMetaInfo, TextBlockWithLabel, } from "../component/ArticleMetaInfo";
import { Text, TokenTextAppearance, ContainerPadding, Container, } from "@volkswagen-onehub/components-core";
import { ConfigurationDetailTable } from "../component/ConfigurationDetailTable";
import { RegisterFeatureAppContextProvider } from "../component/register/RegisterFeatureAppContext";
import { FeatureAppVersionTable, } from "../component/FeatureAppVersionTable";
import { UsageTable } from "../component/UsageTable";
import { ServiceContext } from "../context/ServiceContext";
import { filterProperReleases } from "./filterProperReleases";
import { ErrorBox } from "./ErrorBox";
import { getLastDeploymentDate } from "./getLastDeploymentDate";
const detailFeatureAppQuery = gql(detailFeatureApp);
export function DeploymentView(props) {
    const { appName } = props;
    const [update, setUpdate] = useState(false);
    const layerManager = useContext(ServiceContext).layerManager;
    const { loading, error, data, refetch } = useQuery(detailFeatureAppQuery, {
        variables: { appName, deploymentSetName: "" },
    });
    return (React.createElement(RegisterFeatureAppContextProvider, { refetch: refetch, setLoading: setUpdate, timeout: (timeout) => {
            layerManager.openError("Failed to Register", `Newly registered feature apps were not available on the publisher after ${timeout} seconds. Try again later.`);
        } },
        React.createElement(Loading, { reloadMessage: "loading update ...", reloading: update, key: "deployments", loading: loading, error: error, render: () => {
                var _a;
                if (!data ||
                    !data.allFeatureApps ||
                    data.allFeatureApps.length === 0) {
                    return React.createElement(ErrorBox, { text: `No data for feature app ${appName}` });
                }
                if (!data.getUserGroup) {
                    // get UserGroup is sometimes very slow (>45s)
                    setTimeout(() => refetch(), 500);
                }
                const featureApp = data.allFeatureApps[0];
                const registrationAllowed = data.getUserGroup.featureAppAdmin;
                const isInAppStore = featureApp.general.appStoreAppId;
                // make sure versions are proper releases with the correct name
                const featureAppVersions = featureApp.versions && filterProperReleases(featureApp.versions);
                return (React.createElement(Container, { gutter: "dynamic0200", wrap: "always", padding: ContainerPadding.dynamic0150 },
                    React.createElement(Text, { appearance: TokenTextAppearance.headline400 }, featureApp.general.title),
                    featureApp.general.shortDescription && (React.createElement(Text, { appearance: TokenTextAppearance.copy200 }, featureApp.general.shortDescription)),
                    isInAppStore && ((_a = featureApp === null || featureApp === void 0 ? void 0 : featureApp.latestVersion) === null || _a === void 0 ? void 0 : _a.version) && (React.createElement(ArticleMetaInfo, null,
                        React.createElement(TextBlockWithLabel, { key: "1", label: "Latest version", text: featureApp.latestVersion.version }),
                        featureApp.general.team && (React.createElement(TextBlockWithLabel, { key: "2", label: "Team", text: featureApp.general.team })),
                        featureApp.general.deploymentSet && (React.createElement(TextBlockWithLabel, { key: "3", label: "Deployment set", text: featureApp.general.deploymentSet.name })),
                        featureApp.general.repositoryUrl && (React.createElement(TextBlockWithLabel, { key: "4", href: featureApp.general.repositoryUrl, text: "Github" })),
                        featureApp.general.generalReleasePageUrl && (React.createElement(TextBlockWithLabel, { key: "5", href: featureApp.general.generalReleasePageUrl, text: "Release" })))),
                    isInAppStore && featureAppVersions && (React.createElement(RegisterFeatureAppComponent, { name: appName, integration: getIntegration(registrationAllowed, featureApp), stage: getStage(registrationAllowed, featureApp), production: getProduction(registrationAllowed, featureApp), releases: featureAppVersions.map((v) => ({
                            version: v.version,
                            app: [v],
                        })) })),
                    featureApp.configurations && (React.createElement(React.Fragment, null,
                        React.createElement(ConfigurationDetailTable, { configurations: featureApp.configurations }),
                        React.createElement(UsageTable, { appNames: featureApp.configurations.map((c) => c.name) }))),
                    isInAppStore && featureApp.versions && (React.createElement(FeatureAppVersionTable, { deploymentSet: false, releases: createReleases(featureApp.versions) }))));
            } })));
    function getProduction(registrationAllowed, featureApp) {
        const version = findVersion(featureApp, "prod") || "";
        return {
            id: "prod",
            name: "Production",
            registrationAllowed: registrationAllowed.prod,
            version: version,
            configurations: getConfig("prod", featureApp.configurations),
            lastDeploymentDate: getLastDeploymentDate("prod", version, featureApp.deployments),
        };
    }
    function getStage(registrationAllowed, featureApp) {
        const version = findVersion(featureApp, "stage") || "";
        return {
            id: "stage",
            name: "Stage",
            registrationAllowed: registrationAllowed.stage,
            version,
            configurations: getConfig("stage", featureApp.configurations),
            lastDeploymentDate: getLastDeploymentDate("stage", version, featureApp.deployments),
        };
    }
    function getIntegration(registrationAllowed, featureApp) {
        const version = findVersion(featureApp, "int") || "";
        return {
            id: "int",
            name: "Integration",
            registrationAllowed: registrationAllowed.int,
            version: version,
            configurations: getConfig("int", featureApp.configurations),
            lastDeploymentDate: getLastDeploymentDate("int", version, featureApp.deployments),
        };
    }
}
export function FeatureAppView(props) {
    const { appName } = props;
    return (React.createElement("div", null,
        React.createElement(DeploymentView, { appName: appName })));
}
export const findVersion = (featureApp, env) => {
    const versions = featureApp.configurations &&
        featureApp.configurations
            .reduce((prev, current) => {
            var _a;
            const versions = (_a = current.servers) === null || _a === void 0 ? void 0 : _a.filter((s) => s.server.name === env).map((s) => s.config.version);
            if ((versions === null || versions === void 0 ? void 0 : versions.length) == 1 && versions[0]) {
                prev.push(versions[0]);
            }
            return prev;
        }, [])
            .sort()
            .reverse();
    return versions && versions.length > 0 ? versions[0] : undefined;
};
function getConfig(env, configurations) {
    return configurations === null || configurations === void 0 ? void 0 : configurations.map((c) => {
        var _a;
        const servers = (_a = c.servers) === null || _a === void 0 ? void 0 : _a.filter((s) => s.server.name === env);
        if ((servers === null || servers === void 0 ? void 0 : servers.length) === 1) {
            const server = servers[0];
            return Object.assign({}, server.config);
        }
    }).filter((x) => !!x);
}
function createReleases(versions) {
    return versions.map((v) => (Object.assign({ includedFeatureApps: [] }, v)));
}
