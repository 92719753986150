import { AUTH_TYPE } from "aws-appsync";
import { ApolloLink, ApolloClient, createHttpLink, InMemoryCache, } from "@apollo/client";
import { createAuthLink } from "aws-appsync-auth-link";
import { fetchAuthSession } from "@aws-amplify/auth";
export function createApolloClient(config) {
    const url = config.aws_appsync_graphqlEndpoint;
    const region = config.aws_project_region;
    const auth = {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => { var _a, _b; return ((_b = (_a = (await fetchAuthSession()).tokens) === null || _a === void 0 ? void 0 : _a.idToken) === null || _b === void 0 ? void 0 : _b.toString()) || ""; },
    };
    const authLink = createAuthLink({ url, region, auth });
    const link = ApolloLink.from([authLink, createHttpLink({ uri: url })]);
    return new ApolloClient({
        link,
        cache: new InMemoryCache(),
    });
}
