import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/client";
import { Loading } from "../component/Loading";
import { ArticleMetaInfo, TextBlockWithLabel, } from "../component/ArticleMetaInfo";
import { Text, TokenTextAppearance, ContainerPadding, TokenTextColor, Container, } from "@volkswagen-onehub/components-core";
import { RegisterFeatureAppContextProvider } from "../component/register/RegisterFeatureAppContext";
import { mergeFeatureAppsOfDeploymentSet, } from "../utils/mergeFeatureAppsOfDeploymentSet";
import { detailFeatureApp as deploymentSetQueryString } from "../queries/FeatureApp";
import { ConfigurationDetailTable } from "../component/ConfigurationDetailTable";
import { RegisterFeatureAppComponent, } from "../component/register/RegisterFeatureAppComponent";
import { sortVersion } from "../utils/sortVersion";
import { FeatureAppVersionTable, } from "../component/FeatureAppVersionTable";
import { getVersionAnchor } from "../component/getVersionAnchor";
import { scrollToRelease } from "../component/scrollToRelease";
import { UsageTable } from "../component/UsageTable";
import { ServiceContext } from "../context/ServiceContext";
import { ErrorBox } from "./ErrorBox";
import { getLastDeploymentDate } from "./getLastDeploymentDate";
import { gql } from "../../__generated__";
function getLatestVersion(configs) {
    const versions = configs.reduce((prev, curr) => {
        var _a;
        (_a = curr.servers) === null || _a === void 0 ? void 0 : _a.forEach((server) => {
            const version = server.config.version;
            const env = server.server.name;
            if (version)
                switch (env) {
                    case "stage":
                        prev.stage.add(version);
                        break;
                    case "int":
                        prev.int.add(version);
                        break;
                    case "prod":
                        prev.prod.add(version);
                        break;
                }
        });
        return prev;
    }, {
        prod: new Set(),
        stage: new Set(),
        int: new Set(),
    });
    const getLatest = (versions) => {
        if (versions.size == 0) {
            return "";
        }
        return Array.from(versions).sort(sortVersion)[0];
    };
    return {
        prod: getLatest(versions.prod),
        stage: getLatest(versions.stage),
        int: getLatest(versions.int),
    };
}
function getConfig(env, configurations) {
    return configurations
        .map((c) => {
        var _a;
        const servers = (_a = c.servers) === null || _a === void 0 ? void 0 : _a.filter((s) => s.server.name === env);
        if ((servers === null || servers === void 0 ? void 0 : servers.length) === 1) {
            const server = servers[0];
            return Object.assign({}, server.config);
        }
    })
        .filter((x) => !!x);
}
const deploymentSetQuery = gql(deploymentSetQueryString);
export function DeploymentView(props) {
    const { deploymentSetName } = props;
    const [update, setUpdate] = useState(false);
    const { layerManager } = useContext(ServiceContext);
    const { loading, error, data, refetch } = useQuery(deploymentSetQuery, {
        variables: { deploymentSetName },
    });
    return (React.createElement(RegisterFeatureAppContextProvider, { refetch: refetch, setLoading: setUpdate, timeout: (timeout) => {
            layerManager.openError("Failed to register", `The registered feature apps were not fully available on the publishers after ${timeout} seconds.`);
        } },
        React.createElement(Loading, { key: "deployments", loading: loading, error: error, reloadMessage: "loading update ...", reloading: update, render: () => {
                const noDataFoundMessage = `No data was found for deploymentSet ${deploymentSetName}`;
                if (!(data === null || data === void 0 ? void 0 : data.allFeatureApps) || (data === null || data === void 0 ? void 0 : data.allFeatureApps.length) == 0) {
                    return React.createElement(ErrorBox, { text: noDataFoundMessage });
                }
                const featureApps = data.allFeatureApps;
                const registrationAllowed = data.getUserGroup.featureAppAdmin;
                const deploymentSet = mergeFeatureAppsOfDeploymentSet(featureApps);
                if (!deploymentSet) {
                    return React.createElement(ErrorBox, { text: noDataFoundMessage });
                }
                const versions = getLatestVersion(deploymentSet.configurations);
                return (React.createElement(Container, { gutter: "dynamic0300", wrap: "always", padding: ContainerPadding.dynamic0150 },
                    React.createElement(Container, { gutter: "dynamic0100", wrap: "always" },
                        React.createElement(Text, { appearance: TokenTextAppearance.headline400 },
                            deploymentSet.deploymentSet.name,
                            "\u00A0",
                            React.createElement(Text, { appearance: TokenTextAppearance.headline300, color: TokenTextColor.secondary }, "DeploymentSet")),
                        deploymentSet.deploymentSet.description && (React.createElement(Text, { appearance: TokenTextAppearance.copy200 }, deploymentSet.deploymentSet.description)),
                        React.createElement(ArticleMetaInfo, null,
                            deploymentSet.latestVersion.version && (React.createElement(TextBlockWithLabel, { key: "1", label: "Latest version", text: deploymentSet.latestVersion.version, href: `#${getVersionAnchor(deploymentSet.latestVersion.version)}`, onClick: scrollToRelease(deploymentSet.latestVersion.version) })),
                            deploymentSet.deploymentSet.team && (React.createElement(TextBlockWithLabel, { key: "2", label: "Team", text: deploymentSet.deploymentSet.team })),
                            deploymentSet.repositoryUrls && (React.createElement(TextBlockWithLabel, { key: "4", href: convertSetToString(deploymentSet.repositoryUrls), text: "Github" })),
                            deploymentSet.deploymentSet.releasePageUrl && (React.createElement(TextBlockWithLabel, { key: "4", href: deploymentSet.deploymentSet.releasePageUrl, text: "Release" })))),
                    deploymentSet.versions && (React.createElement(RegisterDeploymentSet, { deployments: deploymentSet.deployments, stage: {
                            version: versions.stage,
                            registrationAllowed: registrationAllowed.stage,
                        }, configurations: deploymentSet.configurations, integration: {
                            version: versions.int,
                            registrationAllowed: registrationAllowed.int,
                        }, production: {
                            version: versions.prod,
                            registrationAllowed: registrationAllowed.prod,
                        }, versions: deploymentSet.versions, name: deploymentSetName })),
                    React.createElement(ConfigurationDetailTable, { configurations: deploymentSet.configurations }),
                    React.createElement(UsageTable, { appNames: deploymentSet.configurations.map((c) => c.name) }),
                    deploymentSet.versions && (React.createElement(FeatureAppVersionTable, { deploymentSet: true, releases: createReleases(deploymentSet.versions, deploymentSet.deploymentSet) }))));
            } })));
}
export function RegisterDeploymentSet(props) {
    const { versions, configurations, name } = props;
    const prodVersion = props.production.version || "";
    const production = {
        id: "prod",
        name: "Production",
        registrationAllowed: props.production.registrationAllowed,
        version: prodVersion,
        configurations: getConfig("prod", configurations),
        lastDeploymentDate: getLastDeploymentDate("prod", prodVersion, props.deployments),
    };
    const stageVersion = props.stage.version || "";
    const stage = {
        id: "stage",
        name: "Stage",
        registrationAllowed: props.stage.registrationAllowed,
        version: stageVersion,
        configurations: getConfig("stage", configurations),
        lastDeploymentDate: getLastDeploymentDate("stage", stageVersion, props.deployments),
    };
    const intVersion = props.integration.version || "";
    const integration = {
        id: "int",
        name: "Integration",
        registrationAllowed: props.integration.registrationAllowed,
        version: intVersion,
        configurations: getConfig("int", configurations),
        lastDeploymentDate: getLastDeploymentDate("int", intVersion, props.deployments),
    };
    return (React.createElement(RegisterFeatureAppComponent, { name: name, integration: integration, stage: stage, production: production, releases: versions
            .filter((v) => v.entries.length > 0)
            .map((v) => ({
            version: v.version,
            app: v.entries,
        })) }));
}
export function DeploymentSetView(props) {
    const { deploymentSetName } = props;
    return (React.createElement("div", null,
        React.createElement(DeploymentView, { deploymentSetName: deploymentSetName })));
}
function convertSetToString(set) {
    return Array.from(set)
        .filter((s) => !!s)
        .join(", ");
}
function createReleases(versions, general) {
    return versions.map((v) => {
        return Object.assign({ includedFeatureApps: v.entries.map((e) => ({
                name: e.name,
                releasePageUrl: e.releasePageUrl || e.generalReleasePageUrl,
            })), publishedAt: v.entries[0].publishedAt, releasePageUrl: general.releasePageUrl }, v);
    });
}
