import React from "react";
import { allFeatureApps } from "../queries/FeatureApp";
import { CTA } from "@volkswagen-onehub/components-core";
import { topLevelColumns } from "./featureAppColumns";
import { ExtendedTable } from "../component/ExtendedTable";
import { gql } from "../../__generated__";
const meta = {
    columns: topLevelColumns.concat([
        {
            header: { label: "Server" },
            field: (d) => {
                return d.server.name;
            },
            groupKey: "servers",
        },
        {
            header: { label: "Version" },
            field: (d) => {
                return d.config.version;
            },
        },
        {
            header: { label: "Author Link" },
            field: (d) => d.config.path,
            render: (d) => {
                return (React.createElement(CTA, { target: "_blank", tag: "a", emphasis: "tertiary", href: `${d.server.authorUrl}/editor.html${d.config.path}.html` }, "edit"));
            },
        },
    ]),
};
const query = gql(allFeatureApps);
export function AllFeatureAppListView() {
    const filterFn = (appStore, data) => {
        return !appStore || !!data.general.appStoreAppId;
    };
    return (React.createElement(ExtendedTable, { query: query, meta: meta, getData: (data) => data.allFeatureApps, filters: [
            {
                label: "App store",
                filterFn,
                defaultValue: true,
                type: "boolean",
            },
        ] }));
}
